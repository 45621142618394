<template>
  <div class="form-wrapper">
    <div class="edit-form-wrapper">
      <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
        <el-row>
          <el-col
            class="form-column"
            :xs="24"
            :sm="18"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item
              prop="name"
              width="20px"
              :label="$t('pages.utilities.cms.contents.edit.form.label.name')"
            >
              <el-input
                v-model="form.name"
                :placeholder="
                  $t('pages.utilities.cms.contents.edit.form.placeholder.name')
                "
              />
            </el-form-item>

            <el-form-item
              :label="
                $t(
                  'pages.utilities.cms.contents.edit.form.properties.type.label'
                )
              "
              prop="type"
              required
            >
              <el-select
                v-model="form.type"
                :placeholder="
                  $t(
                    'pages.utilities.cms.contents.edit.form.properties.type.placeholder'
                  )
                "
              >
                <el-option
                  v-for="item in allowedTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <div class="switch-row">
              <span class="switch-label">
                {{ $t('common.forms.labels.active') }}
              </span>
              <el-switch v-model="form.active" />
            </div>

            <hr class="th-divider" />

            <div class="form-header">
              {{
                $t(
                  'pages.utilities.cms.contents.edit.form.headers.content_configuration'
                )
              }}
            </div>

            <el-form-item
              :label="
                $t(
                  'pages.utilities.cms.contents.edit.form.properties.content_configuration.max_duration.label'
                )
              "
              prop="content_configuration.max_duration"
            >
              <th-number-input
                v-model="form.content_configuration.max_duration"
                :locale="$i18n.locale"
              />
            </el-form-item>

            <el-form-item
              :label="
                $t(
                  'pages.utilities.cms.contents.edit.form.properties.content_configuration.min_duration.label'
                )
              "
              prop="content_configuration.min_duration"
            >
              <th-number-input
                v-model="form.content_configuration.min_duration"
                :locale="$i18n.locale"
              />
            </el-form-item>
          </el-col>

          <el-col
            class="form-column"
            :xs="24"
            :sm="18"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item prop="payload" width="20px" class="zero-margin">
              <div v-if="form.type === 'text'">text</div>
              <div v-else-if="form.type === 'video'">
                <content-video v-model="form.payload" :user="user" />
              </div>
              <div v-else-if="form.type === 'image'">
                <content-image v-model="form.payload" />
              </div>
              <div v-else-if="form.type === 'transition'">transition</div>
              <div v-else>
                {{
                  $t(
                    'pages.utilities.cms.contents.edit.form.properties.payload.placeholder'
                  )
                }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import { mapGetters } from 'vuex'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import ContentImage from './types/image'
import ContentVideo from './types/video'
import { useMessagesStore } from '@/store/messages'

function genInitialData() {
  return {
    name: null,
    type: null,
    content_configuration: {},
    payload_configuration: {},
    payload: null,
    active: true
  }
}

export default {
  components: {
    ContentImage,
    ContentVideo
  },
  data() {
    return {
      loading: false,
      form: genInitialData(),
      payload: {},
      rules: {
        name: [
          {
            max: 255,
            min: 3,
            message: this.$t(
              'pages.utilities.cms.contents.edit.form.properties.name.warning.range',
              { min: 3, max: 255 }
            )
          },
          {
            required: true,
            message: this.$t(
              'pages.utilities.cms.contents.edit.form.properties.name.warning.required'
            )
          }
        ],
        type: [
          {
            required: true,
            message: this.$t(
              'pages.utilities.cms.contents.edit.form.properties.type.warning.required'
            )
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Auth/getUser',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      return !this.$route.params.id
    },
    allowedTypes() {
      return [
        {
          value: 'image',
          label: this.$t(
            'pages.utilities.cms.contents.edit.form.properties.types.image.label'
          )
        },
        {
          value: 'video',
          label: this.$t(
            'pages.utilities.cms.contents.edit.form.properties.types.video.label'
          )
        }
      ]
    }
  },
  mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
    this.$log.debug('content-edit: mounted')
  },
  methods: {
    async fetch(id) {
      const errorMessage = this.$t('common.error.action.read.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      try {
        const inst = th.contents()
        this.loading = true
        this.$log.debug('content-edit: will fetch resource')
        const { data = {} } = await inst.get(id)

        this.$log.debug('content-edit: have fetched resource')

        if (data.id) {
          this.handleItem(data)
          this.$log.debug('content-edit: have handled form data after fetch')
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      }
    },
    submitForm() {
      const warningMessage = this.$t(
        'pages.utilities.cms.contents.edit.form.warnings.invalid_inputs.contents'
      )
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: warningMessage
          })
        }

        this.loading = true
        const payload = this.makeHandleableBody()

        if (this.isNew) return this.create(payload)
        this.alter(this.payload.id, payload)
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    async create(payload) {
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      try {
        const inst = th.contents()

        this.$log.debug('content-edit: will create resource')

        const { data = {}, errors = [] } = await inst.create(payload)

        this.$log.debug('content-edit: have created resource')

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: successMessage
          })

          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'utilities-cms-contents-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'utilities-cms-contents-all' })
          }
        }

        if (errors.length) {
          this.$log.debug(
            'content-create: errors on success',
            JSON.stringify(errors, null, 2)
          )
          errors.forEach((errorObj) => {
            useMessagesStore().setLocalMessage({
              id: errorObj.id,
              label: errorObj.label,
              operation: 'local_message',
              payload: errorObj.errorDetails
            })
          })
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, { message: errorMessage })
      }
    },
    async alter(id, payload) {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      try {
        const inst = th.contents()
        const { data = {} } = await inst.patch(id, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: successMessage
          })

          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, { message: errorMessage })
      } finally {
        this.loading = false
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.insert_id || this.payload.id
      )
      if (confirm) this.delete()
    },
    async delete() {
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.cms_content.singular')
      })
      try {
        const inst = th.contents()
        await inst.delete(this.payload.id)

        this.resetForm()
        this.$router.push({ name: 'utilities-cms-contents-all' })

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, { message: errorMessage })
      }
    },
    makeHandleableBody() {
      const result = {}
      // replace empty objects, emtpy arrays and empty strings with null
      Object.keys(this.form).forEach((key) => {
        if (
          isEmptyObject(this.form[key]) ||
          isEmptyArray(this.form[key]) ||
          isEmptyString(this.form[key])
        ) {
          result[key] = null
        } else {
          result[key] = this.form[key]
        }
      })

      return result
    },
    handleItem(item) {
      this.payload = { ...item }

      const cleanedPayload = pick(item, Object.keys(this.form))

      if (!cleanedPayload.content_configuration)
        cleanedPayload.content_configuration = {}
      if (!cleanedPayload.payload_configuration)
        cleanedPayload.payload_configuration = {}

      this.form = {
        ...this.form,
        ...cleanedPayload
      }

      this.$log.debug('form', this.form)
    },
    resetForm(formName) {
      this.$log.debug('content-edit: will reset form')

      if (this.isNew) {
        this.$refs.form.resetFields()
      } else {
        this.handleItem(this.payload)
      }
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  height: 100%;
  overflow: auto;
}

.form-column {
  margin: 0;
  padding: 1em;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
}

.form-header {
  font-weight: bold;
  margin-top: 20px;
}

.el-form-item {
  margin: 10px 0 20px 0;
}

.zero-margin {
  margin: 0px;
}

.required-asterisk {
  color: var(--secondary-color);
}

.midst-body {
  margin-top: 40px;
}

.required-marker {
  position: absolute;
  right: -15px;
}

.el-row {
  max-width: 1200px;
}

.el-date-editor {
  width: 100%;
}

.switch-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning-unsaved-changes {
  margin-top: 80px;
  color: red;
}

.auto-hint {
  line-height: 12pt;
  color: #c0c4cc;
}

.image-container {
  min-width: 140px;
  max-width: 140px;
  height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  overflow: auto;
}

.image {
  width: 100%;
  height: 100%;
}
</style>
