<template>
  <div>
    <h3>
      {{ $t('pages.utilities.cms.contents.edit.form.properties.type.header') }}
    </h3>
    <span>{{
      $t(
        'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.description'
      )
    }}</span>
    <el-input
      :model-value="modelValue"
      :placeholder="
        $t(
          'pages.utilities.cms.contents.edit.form.properties.type.video.placeholder'
        )
      "
      @input="(v) => $emit('update:modelValue', v)"
    />

    <span>{{
      $t(
        'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.divider'
      )
    }}</span>

    <div v-loading="videoLoading" class="custom-uploader">
      <el-upload
        ref="uploader"
        class="video-uploader"
        action=""
        :on-change="onChange"
        :on-remove="handleListRemove"
        :file-list="fileList"
        :on-exceed="handleExceeded"
        :on-error="handleUploadError"
        :on-success="handleUploadSuccess"
        :auto-upload="false"
        :limit="1"
        accept="video/mp4,video/x-m4v,video/x-quicktime,video/quicktime"
      >
        <el-button v-if="fileList.length === 0" size="small" icon="Upload">
          {{
            $t(
              'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.click_to_upload'
            )
          }}
        </el-button>
        <template #tip>
          <div
            v-if="fileList.length === 0"
            class="el-upload__tip leading-none mt-3 mb-0"
          >
            {{
              $t(
                'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.not_larger_than'
              )
            }}
          </div>
        </template>
      </el-upload>
    </div>

    <hr class="th-divider" />

    <div class="video-container">
      <div class="video-wrap">
        <vue3-video-player
          :src="modelValue"
          class="vjs-custom-skin"
          v-bind="playerOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid'
import th from '@tillhub/javascript-sdk'

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    user: {
      type: String,
      required: true
    }
  },
  data() {
    const fileList = []
    if (this.modelValue) {
      fileList.push({
        name: this.modelValue.substring(this.modelValue.lastIndexOf('/') + 1),
        url: this.modelValue
      })
    }
    return {
      fileList,
      videoData: null,
      videoId: null,
      videoLoading: false
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    // assetsAPIBase () {
    //   // return `${constructBase()}/api/v0/`
    //   return `http://localhost:3010/api/v0/videos/${this.user}/contentVideo`
    // },
    playerOptions() {
      return {
        autoplay: true,
        muted: false,
        lang: 'en',
        controls: true,
        loop: true
      }
    }
  },
  methods: {
    handleListChange(file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    handleListRemove(file, fileList) {
      this.fileList = fileList
      this.$emit('update:modelValue', null)
    },
    validateFiles(file) {
      const isValidSize = file.size / 1024 / 1024 <= 500
      const isValidExtension = /\.(mov|mp4)$/i.test(file.name)

      if (!isValidSize) {
        this.$message.error(
          this.$t(
            'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.too_large'
          )
        )
      }

      if (!isValidExtension) {
        this.$message.error(
          this.$t(
            'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.invalid_extension'
          )
        )
      }

      return isValidSize && isValidExtension
    },
    handleExceeded() {
      this.$message.error(
        this.$t(
          'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.too_many'
        )
      )
    },
    handleUploadError(err) {
      this.$message.error(
        `${this.$t(
          'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.upload_error'
        )}: ${err.message}`
      )
    },
    handleUploadSuccess() {
      this.$message.success(
        this.$t(
          'pages.utilities.cms.contents.edit.form.properties.type.video.instruction.upload_success'
        )
      )
    },
    async uploadVideo(file) {
      try {
        const bodyFormData = new FormData()
        bodyFormData.append('video', new Blob([file]), file.name)

        const ext = file.name.match(/\.[0-9a-z]+$/i)[0] || ''

        let data
        this.videoLoading = true
        if (!this.videoId) {
          this.videoId = uuid.v4()
          data = await th
            .videos()
            .create(
              { subsystem: 'contentVideos', prefix: this.videoId, ext },
              bodyFormData
            )
        } else {
          data = await th
            .videos()
            .put(
              { subsystem: 'contentVideos', prefix: this.imageId, ext },
              bodyFormData
            )
        }

        this.videoData = data

        this.videoLoading = false

        if (this.videoData.data) {
          this.$emit('update:modelValue', this.videoData.data.original)
        }
      } catch (err) {
        this.videoLoading = false
        this.$logException(err, {
          message: 'Could not upload video'
        })
      }
    },
    async onChange(file) {
      if (!this.validateFiles(file)) {
        this.$refs.uploader.clearFiles()
        return
      }

      this.uploadVideo(file.raw)

      try {
        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = () => {
          this.image = reader.result
        }
        reader.onerror = function (err) {
          throw err
        }
      } catch (err) {
        this.$logException(err)
      }
    }
  }
}
</script>

<style scoped>
.video-wrap {
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #c1c1c1;
}

.vjs-custom-skin {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}
.vjs-custom-skin :deep(div.video-js) {
  width: 100% !important;
  height: 100% !important;
}
.vjs-custom-skin :deep(video) {
  width: 100% !important;
  height: 100% !important;
  /* background: yellow; */
  /* z-index: 1; */
}

.custom-uploader {
  border: 1px dashed #cacaca;
  border-radius: 5px;
  min-height: 90px;
  width: 100%;
  overflow: hidden;
  padding: 1rem;
}

.video-uploader :deep(*.el-upload) {
  display: block;
  /* height: 0; */
}
/* .video-wrap * {
  pointer-events: none;
  user-select: none;
} */
</style>
