<template>
  <th-page-wrapper
    class="cms-contents-edit"
    :title="$t('common.resource.cms_content.singular')"
    color="var(--secondary-color)"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'utilities-cms-contents-all' })"
  >
    <resource-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import ResourceForm from './components/form.vue'

export default {
  components: {
    ResourceForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        returnedPath: { name: 'utilities-cms-contents-all' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  height: 100%;
}
</style>
