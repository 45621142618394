<template>
  <div>
    <h3>
      {{ $t('pages.utilities.cms.contents.edit.form.properties.type.header') }}
    </h3>
    <span>{{
      $t(
        'pages.utilities.cms.contents.edit.form.properties.type.image.instruction.description'
      )
    }}</span>
    <el-input
      :model-value="modelValue"
      :placeholder="
        $t(
          'pages.utilities.cms.contents.edit.form.properties.type.image.placeholder'
        )
      "
      @input="(v) => $emit('update:modelValue', v)"
    />

    <span>{{
      $t(
        'pages.utilities.cms.contents.edit.form.properties.type.image.instruction.divider'
      )
    }}</span>

    <th-image-upload-v2
      show-long-size-info
      resource="contentImage"
      image-key="1x"
      height="130px"
      fill-type="height"
      :model-value="{ '1x': modelValue }"
      @update:modelValue="handleImage"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    image() {
      return this.modelValue || ''
    }
  },
  methods: {
    handleImage(image) {
      const imageValue = image ? image['1x'] : image
      this.$emit('update:modelValue', imageValue)
    }
  }
}
</script>
